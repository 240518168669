

const About = () => {
    return (
        <div className="about">
            <p className="about__copy">
            Hello! I'm Tim Wallace, a full-stack developer with a background in jazz trombone. My musical roots have instilled in me the importance of timing, nuance, and simplicity, teaching me that less is often more—a principle I carry into my coding work. I excel at building user-centered websites with modern frameworks like React and Express. When I'm not writing code, I’m on my YouTube channel, where I take on CSS challenges and share my techniques and insights. My passion for both learning and teaching drives me to continuously grow as a developer.
            </p>
        </div>
    )
}

export default About;